import {CharonResource} from '@/utils'

export default {
  // 发送验证码
  sendVerificationCode: CharonResource('/user/sendVerificationCode', 'post'),
  // 短信登录
  smsLogin: CharonResource('/user/smsLogin', 'post'),
  // 登录
  userLogin: CharonResource('/userLogin/login', 'post'),
  // 提交信息
  submitForm: CharonResource('/user/add', 'post'),
  // 获取用户信息
  getUserInfo: CharonResource('/user/getByPhone', 'get'),
  // 获取用户信息
  getByHubId: CharonResource('/user/getByHubId', 'get'),
  // 获取可预约日期数据
  getValidDate: CharonResource('/calendar/page', 'get'),
  // 添加预约
  addReservation: CharonResource('/trainingInfo/add', 'post'),
  // 取消预约
  deleteReservation: CharonResource('/trainingInfo/delete', 'post'),
  // 检查用户是否是白名单
  checkWhite: CharonResource('/user/isWhiteList', 'get'),
  // 查询预约信息
  getReservation: CharonResource('/trainingInfo/getAppointmentByPhone', 'get'),
  // 查询取消预约信息
  getCanelReservation: CharonResource('/trainingInfo/getCancelAppointmentByHubId', 'get'),
  // 查询经销商列表
  getDealer: CharonResource('/dealer/page', 'get'),
  // 查询预约数
  getReservationCount: CharonResource('/trainingInfo/getAppointmentCount', 'get'),

  // 获取报名信息
  getCourseEnrollmentByPhone: CharonResource('/courseEnrollment/getByPhone', 'get'),
  // 通过手机号查询个人课程报名信息
  getCourseEnrollmentInfo: CharonResource('/courseEnrollment/getEnrollmentByPhone', 'get'),
  // 获取课程排课信息
  getCourseDetailList: CharonResource('/courseDetail/page', 'get'),
  // 获取课程排课信息
  getCourseDetail: CharonResource('/courseDetail/get', 'get'),
  // 获取课程信息
  getCourse: CharonResource('/course/get', 'get'),
  // 报名
  addEnrollmentDetail: CharonResource('/courseEnrollmentDetail/add', 'post'),
  // 获取报名
  getEnrollmentDetail: CharonResource('/courseEnrollmentDetail/getByPhone', 'get'),
  // 取消报名
  cancelEnrollment: CharonResource('/courseEnrollmentDetail/cancel', 'post'),
  getEnrollmentDetailList: CharonResource('/courseEnrollmentDetail/page', 'get'),

  // 获取图片
  getCourseImage: CharonResource('/courseImage/query', 'post', {'responseType': "blob"}),
  getCourseImage2: CharonResource('/courseImage/query2', 'get', {'responseType': "blob"}),

  // 获取播放凭证
  createPlayAuth: CharonResource('/trainingClassify/createPlayAuth', 'post'),
  // 获取视频信息
  getVideoInfo: CharonResource('/trainingClassify/getVideoInfo', 'get'),
  // 获取学习清单
  getTrainingList: CharonResource('/trainingDetailList/getClientList', 'get'),
  // 增加学习记录
  addTrainingDetailLog: CharonResource('/trainingDetailLog/add', 'post'),
  // 修改学习记录
  updateTrainingDetailLog: CharonResource('/trainingDetailLog/update', 'post'),

  //GM
   // 获取GM可预约日期数据
   getGmValidDate: CharonResource('/gmCalendar/page', 'get'),
     // 添加预约
  addGmAppointment: CharonResource('/gmAppointment/add', 'post'),
  // 取消预约
  deleteGmAppointment: CharonResource('/gmAppointment/delete', 'post'),
 // 查询预约信息
 getAppointmentByHubId: CharonResource('/gmAppointment/getAppointmentByHubId', 'get'),
 getQuizStatus: CharonResource('/gmAppointment/getQuizStatus', 'get'),
  
 getConfig: CharonResource('/sysConfig/getByKey', 'get'),
}
