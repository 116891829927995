import {Charon} from '@/utils'

// Charon.defaults.baseURL = 'http://47.93.42.179:8090/bentley'
// Charon.defaults.baseURL = 'http://192.168.101.24:8010/bentley'
// Charon.defaults.baseURL = 'https://test.api.bentley.levsoft.cn/bentley'
Charon.defaults.baseURL = process.env.VUE_APP_BASE_API 
Charon.defaults.withCredentials = true

const token = sessionStorage.getItem('token') || null
Charon.defaults.headers.common['token'] = token


// 落地页接口
export {default as Landing} from './landing'

Charon.interceptors.request.use((request = {}) => {
  return request
})

// 错误处理
Charon.interceptors.response.use(response => {
  return response
})