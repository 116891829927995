<template>
  <div class="app-container">
    <!-- <div class="prism-player" id="player-con"></div> -->
    <div id="J_prismPlayer"></div>
    <!-- <van-overlay class="traingin-van" :style="{'--vanleft':vanleft, '--vantop':vantop}" :show="vanshow" @click="vanshow = false" >
      <img class="training-img" src="../assets/images/rotate.png" />
    </van-overlay> -->
    <!-- <div id="player-con"></div>  -->
    <!-- <div id="player-con" ref="videoWrap"></div>  -->
  </div>
</template>
<script>
import {Landing} from '@/network'
import {isIOS} from '@/utils'
import { Overlay } from 'vant';

export default {
  name: 'VideoContent',
  components: {
      vanOverlay: Overlay
  },
  data() {
    return {
      hubId: sessionStorage.getItem('hubId') || null,
      phone: sessionStorage.getItem('phone') || null,
      videoId: this.$route.params.videoId || null,
      detailLogId: this.$route.params.detailLogId || null,
      screen: 0,
      player: '',
      timer: null,
      vanshow: true,
      vantop: '0px',
      vanleft: '0px',
    }
  },
  mounted() {
    this.playVideo()
    // this.setWatermark()
    this.timer = setInterval(() => {
      if (this.player) {
        if (this.player.getStatus() == 'ready' || this.player.getStatus() == 'playing') {
          // console.warn('播放到 ', this.player.getCurrentTime())
          Landing.updateTrainingDetailLog({
            id: this.detailLogId,
            status: 1,
            playTime: this.player.getCurrentTime(),
            totalTime: this.player.getDuration()
          })
        }
        if (this.player.getStatus() == 'ended') {
          console.log("播放完了")
          Landing.updateTrainingDetailLog({
            id: this.detailLogId,
            status: 2,
            playTime: this.player.getCurrentTime()
          })
          clearInterval(this.timer)
        }
      }
    }, 1000)
  },
  destroyed() {
    // this.removeWatermark()
    clearInterval(this.timer)
  },
  // async created() {
  //   this.setVanImg()
  // },
  methods: {
    setVanImg() {
      let height = document.documentElement.clientHeight
      let width = document.documentElement.clientWidth
      if (height > width) {
        this.vanshow = true
        let lside = 6
        let tside = 55
        this.vantop = (height - 40 - tside) + 'px'
        this.vanleft = (width - 40 - lside) + 'px'
      } else {
        // this.vanshow = false
      }
    },
    async playVideo() {
      const {data} = await Landing.createPlayAuth({
        videoId: this.videoId
      })
      if (data) {
        let videoInfo = await Landing.getVideoInfo({videoId: this.videoId})
        let screen = videoInfo.screen
        this.screen = screen

        let videoFormat = 'm3u8'
        let encryptType = 1
        if (isIOS()) {
          videoFormat = 'mp4'
          encryptType = 0
        }

        if (this.player) {
          this.player.dispose()
        }
        let isRotate = false //(screen == 1 && document.documentElement.clientHeight > document.documentElement.clientWidth) ? true : false
        let videoWidth = (isRotate) ? document.documentElement.clientHeight  + 'px' : document.documentElement.clientWidth  + 'px'
        let videoHeight = (isRotate) ? document.documentElement.clientWidth  + 'px' : document.documentElement.clientHeight  + 'px'
        this.player = new Aliplayer({
          // "id": "player-con",
          "id": "J_prismPlayer",
          "vid": this.videoId,
          "playauth": data.playAuth,
          "cover": data.videoMeta.coverURL,
          "qualitySort": "asc",
          "format": videoFormat,
          "mediaType": "video",
          "width": "100%",
          "height": "100%",
          // "videoWidth": videoWidth,
          // "videoHeight": videoHeight,
          "autoplay": true,
          "isLive": false,
          "rePlay": false,
          "playsinline": true,
          "preload": true,
          "controlBarVisibility": "click",
          "useH5Prism": true,
          "x5_type": 'h5',
          "x5_video_position": 'center',
          "x5_fullscreen": false,   
          // "x5_orientation": "landscape",
          "x5_orientation": "portraint",
          // "x5LandscapeAsFullScreen": false,   xxxx
          // "enableSystemMenu": false,
          "encryptType": encryptType,
          "skinLayout": [
            {"name": "bigPlayButton", "align": "cc"},
            {"name": "H5Loading", "align": "cc"},
            {"name": "errorDisplay", "align": "tlabs", "x": 0, "y": 0},
            {"name": "infoDisplay"},
            {
              "name": "controlBar", "align": "blabs", "x": 0, "y": 0,
              "children": [
                {"name": "progress", "align": "blabs", "x": 0, "y": 44},
                {"name": "playButton", "align": "tl", "x": 15, "y": 12},
                {"name": "timeDisplay", "align": "tl", "x": 10, "y": 7},
                {"name": "fullScreenButton", "align": "tr", "x": 10, "y": 12},
                {"name": "volume", "align": "tr", "x": 5, "y": 10},
              ]
            }
          ]
        }, function (player) {
            console.log("The player is created");
            // player.fullscreenService.requestFullScreen()
            // if (isRotate) {
            //   player.setRotate(90)
            // }
          }
        )
        // this.player.on('play', function(){
        //   console.log("xxxxx play")
        // })
        // this.player.on('ended', function() {
        //   console.log("xxxxxxx 播放完了")
        // })
        // this.player.on('cancelFullScreen', e => {
        //   console.log("xxxxxxx 取消全屏")
        // })
        // this.player.on('requestFullScreen', () => {
        //   console.log("xxxxxxx 全屏")
        // })

        window.onresize = () => {
          // let width = document.documentElement.clientWidth
          // let height = document.documentElement.clientHeight
          // if (width > height && this.player.getRotate != 0) {
          //   this.player.setRotate(0)
          // } else {
          //   this.player.setRotate(90)
          // }
          // this.removeWatermark()
          // this.setWatermark()
          // this.setVanImg()
        }
      }
    },
    removeWatermark() {
      let id = '1.23452384164.123412416';
      if (document.getElementById(id) !== null) {
        document.body.removeChild(document.getElementById(id));
      }
    },
    setWatermark() {
      let id = '1.23452384164.123412416';
      if (document.getElementById(id) !== null) {
          document.body.removeChild(document.getElementById(id));
      }

      let left = 90
      let right = 90
      let top = 80
      let bottom = 80
      let fontSize = 20

      let width = document.documentElement.clientWidth
      let height = document.documentElement.clientHeight
      let rotate = (width < height) ? 90 : 0

      //创建一个画布
      const can = document.createElement('canvas');
      let ctx = can.getContext('2d');
      const contentWidth = ctx.measureText(this.hubId).width;
      // console.warn('字体宽 = ', contentWidth)

      can.width = left + right + contentWidth
      can.height = top + bottom + fontSize
      const {x, y} = this.getCenter(contentWidth, top, left, fontSize)

      if (rotate != 0) {
        ctx.translate(x, y)
        ctx.rotate(rotate * Math.PI / 180)
        ctx.translate(-x, -y)
      }
      // console.warn('画布宽高 ', can.width, can.height)
      // console.warn('移动点 ', x, y)

      ctx.font = `{fontSize}px Vedana`;
      //设置填充绘画的颜色、渐变或者模式
      ctx.fillStyle = 'rgba(200, 200, 200, 0.40)';
      //设置文本内容的当前对齐方式
      ctx.textAlign = 'left';
      //设置在绘制文本时使用的当前文本基线
      ctx.textBaseline = 'top';
      //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
      ctx.fillText(this.hubId, left, top);

      let div = document.createElement('div');
      div.id = id;
      div.style.pointerEvents = 'none';
      div.style.top = '5px';
      div.style.left = '5px';
      div.style.position = 'fixed';
      div.style.zIndex = '99900000';
      div.style.width = document.documentElement.clientWidth + 'px';
      div.style.height = document.documentElement.clientHeight + 'px';
      div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
      div.style.display = "block";
      div.style.pointerEvents = 'none';
      div.style.visibility = "";
      div.style.overflow = "hidden";
      document.body.appendChild(div);
      return id;
    },
    getCenter(contentWidth, top, left, fontSize) {
      return {
        x: contentWidth / 2 + left,
        y: fontSize / 2 + top
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.header {
  width: 100%;
  height: 211px;
  position: relative;
  img {
    width: 100%;
    height: 211px;
    object-position: center;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
  }
  .video-wrap {
    width: 100%;
    height: 211px !important;
  }
}
.text-content {
  width: 100%;
  height: calc(100vh - 211px);
  box-sizing: border-box;
  padding: 25px 20px;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  text-align: justify;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
.traingin-van {
    position: absolute;
    left: var(--vanleft);
    top: var(--vantop);
    z-index: 99900099;
    width: 40px;
    height: 40px;
    background:rgba(255,255,255,0);
    .training-img {
        width: 40px;
        height: 40px;
    }
}
</style>
<style lang="scss">
.video-content-page {
  .text-content {
    img {
      width: 100% !important;
      height: auto !important;
      object-position: top;
      object-fit: contain;
    }
  }
}
.prism-player .prism-cover {
  width: 100%;
  height: auto;
}
</style>
